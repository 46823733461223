<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>   Component's List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="mytable"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-width="100" data-field="code" >Code</th>
          <th data-field="name" >Name</th>
          <th data-field="group.name" >Group</th>
          <th data-field="category.name" >Category</th>
          <th data-field="brand.name" >Brand</th>
          <th data-field="group.hsn" >HSN</th>
          <th data-field="group.gstrate" >GST</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->


    <vmodal name="component_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="85%" :height="500" @before-open="beforeOpen" @before-close="beforeClose" >
      <ComponentForm></ComponentForm>
    </vmodal>

  </div>

</template>


<script>
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import ComponentForm from '@/components/v1/component/ComponentForm.vue'
  export default {
    name: 'ComponentView',
    components: {
      ComponentForm,
      FabButton,
      moment
    },
    data () {
      return {
        readonly: false,
        component: JSON.parse('{"id":"","name":"","p_name":"","hsn":"","type":0,"nature":0,"gstrate":0,"itcrate":100, "unit":{"code":1,"name":"","digits":0,"sys_obj":false}, "conv_fact":1, "subunit":{"code":1,"name":"","digits":0,"sys_obj":false},  "sys_obj":false}'),
        mytable: {},
        units: []
      }
    },
    created () {

    },
    mounted () {
      let self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('.form-control-select2').select2()

      $('[data-toggle="popover"]').popover();

      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }

        }
      });

      $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#component_modal_window').on('shown.bs.modal', function () {
        $('#txtname').trigger('focus');
      });

      $('#component_modal_window').on('hide.bs.modal', function () {
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#component_modal_window').modal('hide');
      });

      self.loadData();

    },
    methods: {
      when_opened(){

      },
      closeModal () {
        this.$modal.hide('component_modal_window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
        this.item = JSON.parse('{"id": 0, "code": "", "name": "", "gstSlab":"","printName": "", "parent": 0, "type": 0, "nature": 0, "hasInsurance": false, "hasAMC": false, "hasComponents": false, "hasBatch": false,"gstSlab": {"id": 0, "code": "", "name": ""},  "depreciationLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"code": 0, "name": ""}}, "purchaseLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "saleLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "amcPercent": 0.0, "lifeInResidence": 0, "lifeInBranch": 0, "noOfBarcodes": 0, "list": []}');
      },
      showModal () {
        this.item = JSON.parse('{"id": 0, "code": "", "name": "", "gstSlab":"","printName": "", "parent": 0, "type": 0, "nature": 0, "hasInsurance": false, "hasAMC": false, "hasComponents": false, "hasBatch": false,"gstSlab": {"id": 0, "code": "", "name": ""},  "depreciationLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"code": 0, "name": ""}}, "purchaseLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "saleLedger": {"id": 1, "code": "", "name": "", "alias": "", "group": {"id": 0, "name": ""}}, "amcPercent": 0.0, "lifeInResidence": 0, "lifeInBranch": 0, "noOfBarcodes": 0, "list": []}');
        this.$modal.show('component_modal_window')
      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.group = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/component/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.group = data.data;
            self.$modal.show('component_modal_window');
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.group.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.group)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/component/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('component_modal_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
              // swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/component/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.mytable.bootstrapTable('load', data.data);
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      save () {
        alert('Welcome')
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
