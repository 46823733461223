<template>
  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Component Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Group:</label>
          <div class="col-lg-10">
            <select  id="cmbgroup" class="form-control form-control-select2" data-fouc v-if="item" v-model="item.code">
              <option v-for="group in groups" v-bind:value="group.code">
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter Item Name here..." maxlength="100" v-if="item" v-model="item.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Print Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Category:</label>
          <div class="col-lg-4">
            <select id="cmbcategory" class="form-control form-control-select2" data-fouc v-if="item" v-model="item.category.code">
              <option v-for="category in categories" v-bind:value="category.code">
                {{ category.name }}
              </option>
            </select>
          </div>

          <label class="col-lg-1 col-form-label">Brand:</label>
          <div class="col-lg-5">
            <select id="cmbbrand" class="form-control form-control-select2" data-fouc v-if="item" v-model="item.brand.code">
              <option v-for="brand in brands" v-bind:value="brand.code">
                {{ brand.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Unit of measure:</label>
          <div class="col-lg-4">
            <select id="cmbunit" class="form-control form-control-select2" data-fouc v-if="item" v-model="item.unit.code">
              <option v-for="unit in units" v-bind:value="unit.code">
                {{ unit.name }}
              </option>
            </select>
          </div>

          <label class="col-lg-1 col-form-label">Min Qty:</label>
          <div class="col-lg-2">
            <input type="number" class="form-control text-right" placeholder="min stock qty" min="0">
          </div>

          <label class="col-lg-1 col-form-label">Max Qty:</label>
          <div class="col-lg-2">
            <input type="number" class="form-control text-right" placeholder="max stock qty" min="0">
          </div>

        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Purchase Ledger:</label>
          <div class="col-lg-4">
            <select id= "cmbpurchledger" class="form-control form-control-select2" data-fouc >
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-lg-1 col-form-label">Sale Ledger:</label>
          <div class="col-lg-5">
            <select id="cmbsaleledger" class="form-control form-control-select2" data-fouc>
              <option value="1">Physical Product</option>
              <option value="2">Virtual Product</option>
              <option value="3">Services</option>
            </select>
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button id="btnsave" type="button" class="btn btn-primary" @click="saveComponent">Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ComponentForm',
  data () {
    return {
      item: JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"system_object":false}'),
      groups: [],
      categories: [],
      brands: [],
      units: [],
      purchledgers:[],
      saleledgers: []
    }
  },
  component: {

  },
  created () {
    this.$data.item = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"system_object":false}');
  },
  mounted () {
    this.loadGroup();
    this.loadCategory();
    this.loadBrand();
    this.loadUnits();
    $('#cmbgroup').focus();
  },
  methods: {
    closeWindow() {
      this.$parent.$parent.closeModal();
    },
    clear(){
      this.$data.item = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"system_object":false}');
    },
    loadGroup(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.groups = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/master/componentgroup/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.groups = data.data;
          $('#cmbgroup').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    loadCategory(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/category/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.categories = data.data;
          $('#cmbcategory').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    loadBrand(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.brands = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/master/brand/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.brands = data.data;
          $('#cmbbrand').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    loadUnits(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.units = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/master/unit/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.units = data.data;
          $('#cmbunit').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    loadPurchaseLedgers(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.purchledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/master/ledger/group/code/25/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.purchledgers = data.data;
          $('#cmbpurchledger').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    loadSaleLedgers(){
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.purchledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/master/ledger/group/code/34/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.saleledgers = data.data;
          $('#cmbsaleledger').select2();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    saveComponent(){
      alert('Save');
    }
  }
}
</script>

<style scoped>

</style>
